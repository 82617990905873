import clsx from "clsx"

export type PageLayoutProps = {
    className?: string
    children?: any
}

export const PageLayout = (props: PageLayoutProps) => {
    return (
        <div
            className={clsx(
                "mx-6",
                "sm:mx-auto sm:w-148",
                "lg:mx-auto lg:w-200",
                props.className
            )}
        >
            {props.children}
        </div>
    )
}